enum EPartnerRequests {
  PROVIDING_OFFER = 'PROVIDING_OFFER',
  OFFER_PROVIDED = 'OFFER_PROVIDED',
  PROVIDING_ANSWER = 'PROVIDING_ANSWER',
  ANSWER_PROVIDED = 'ANSWER_PROVIDED',
  PROVIDING_ICE_CANDIDATE = 'PROVIDING_ICE_CANDIDATE',
  ICE_CANDIDATE_PROVIDED = 'ICE_CANDIDATE_PROVIDED',
}

enum EServerEvents {
  GET_ICE_SERVERS = 'get_ice_servers',
  REGISTER_CLIENT = 'register_client',
  JOIN_ROOM = 'join',
  GET_CLIENTS = 'get_clients',
  OTHER_USER_JOIN = 'notify_join',
  OTHER_USER_LEAVE = 'notify_leave',
  INCOMING_REQUEST = 'incoming_request',
  PARTNER_REQUEST = 'client_request',
}

enum EMediaErrors {
  NOT_FOUND = 'Requested device not found',
  PERMISSION_DENIED = 'Permission denied',
  SOURCE_UNAVAILABLE = 'Source unavailable',
  LOWQ_VIDEO_UNAVAILABLE = 'Low quality video unavailable',
  CANT_START_SOURCE = 'Could not start video source',
}
enum EMediaErrorsTypes {
  USER_AUDIO = 'user_audio',
  USER_VIDEO = 'user_video',
  USER_DEVICES = 'user_devices',
  USER_VIDEO_RESOLUTION = 'user_videoResolution',
  DESKTOP_VIDEO = 'desktop_video',
}

const MEDIA_ERRORS_MESSAGES_KEYS = {
  [EMediaErrorsTypes.USER_AUDIO]: 'userAudioError',
  [EMediaErrorsTypes.USER_VIDEO]: 'userVideoError',
  [EMediaErrorsTypes.USER_DEVICES]: 'devicesObtainError',
  [EMediaErrorsTypes.DESKTOP_VIDEO]: 'desktopVideoError',
  [EMediaErrors.NOT_FOUND]: 'deviceNotFound',
  [EMediaErrors.PERMISSION_DENIED]: 'permissionDenied',
  [EMediaErrors.SOURCE_UNAVAILABLE]: 'sourceUnavailable',
  [EMediaErrors.LOWQ_VIDEO_UNAVAILABLE]: 'lowQualityVideoUnavailable',
  [EMediaErrors.CANT_START_SOURCE]: 'streamNotObtained',
};


export {
  EPartnerRequests,
  EServerEvents,
  EMediaErrors,
  EMediaErrorsTypes,
  MEDIA_ERRORS_MESSAGES_KEYS
};
