import * as Sentry from '@sentry/vue';
import { defineServerUrl, delay, waitUntilBecomes } from '@jcc/tools/utils';
import type { AudioVideoData } from '@jcc/tools/interfaces';
import CONFIG from '@jcc/config';
import { EServerEvents, EPartnerRequests } from './constants';
import WsRpcClient from './wsRpcClient';
import { trySetVideoCodec, getVersionOfIOS } from './utils';


type Streams = {
  provided: null | MediaStream;
  received: null | MediaStream;
};
type JccRole = 'viewer' | 'provider';
type PartnerAwaitStatus = { isConnected: boolean };
type ServerDataICE = {
  urls: string;
  credential?: string;
  username?: string;
};
type RoomParticipant = { client_id: string, role: JccRole };
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type EventHandlers<T> = Record<keyof T, (e: any) => void>;
type ConnectorEventHandlers = {
  peer: EventHandlers<RTCPeerConnectionEventMap>;
  dataChannel?: EventHandlers<RTCDataChannelEventMap>;
};

class Connector {
  #wsClient!: WsRpcClient;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  #streams!: any;
  #viewerId!: string;
  #peer!: RTCPeerConnection;
  #dataChannel!: RTCDataChannel;
  #jccRole!: JccRole;
  #partnerAwaitStatus!: PartnerAwaitStatus;
  #iceServers: ServerDataICE[] = [];
  #candidatesList: RTCIceCandidate[] = [];
  #isTracksProvided: AudioVideoData<boolean> = { audio: false, video: false };
  #isInitialised = false;
  #currentConnectionState = '';
  #eventsHandlers: ConnectorEventHandlers = {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    peer: {
      icecandidate: this.#provideIceCandidate.bind(this),
      track: this.#handleIncomingTracks.bind(this),
      negotiationneeded: this.#provideOffer.bind(this),
      iceconnectionstatechange: this.#handleConnectionStateChange.bind(this),
    },
  };

  get #peerEventsList(): Array<keyof RTCPeerConnectionEventMap> {
    return Object.keys(this.#eventsHandlers.peer) as Array<keyof RTCPeerConnectionEventMap>;
  }

  constructor(role: JccRole) {
    this.#jccRole = role;
  }

  public async startSession(partnerAwaitStatus: PartnerAwaitStatus): Promise<void> {
    this.#partnerAwaitStatus = partnerAwaitStatus as PartnerAwaitStatus;
    this.#initSocket();
    const { iceServers } = await this.#wsClient.send(
      { type: EServerEvents.GET_ICE_SERVERS },
      CONFIG.SERVER_RESPONSE_AWAIT_TIME,
    );
    this.#iceServers = iceServers;
  }

  public async connectToRoom(id: string, streams: Streams): Promise<void> {
    this.#streams = streams;
    await this.#wsClient.send(
      { type: EServerEvents.REGISTER_CLIENT },
      CONFIG.SERVER_RESPONSE_AWAIT_TIME,
    );
    await this.#wsClient.send(
      {
        type: EServerEvents.JOIN_ROOM,
        data: {
          room_id: id,
          role: this.#jccRole,
        },
      },
      CONFIG.SERVER_RESPONSE_AWAIT_TIME,
    );
    if (this.#jccRole === 'provider') {
      const roomUsers: RoomParticipant[] = await this.#wsClient.send({
        type: EServerEvents.GET_CLIENTS,
        data: { room_id: id },
      });
      if (roomUsers.length > 1) {
        const otherUserId = (roomUsers.find(
          (user) => user.role !== this.#jccRole
        ) as RoomParticipant).client_id;
        this.#connectWithOtherUser(otherUserId);
      }
    }
  }

  public reformProvidedStream(isTracksProvided?: AudioVideoData<boolean>): void {
    if (!this.#peer) return;
    const trackSenders = this.#peer.getSenders();
    if (trackSenders.length) {
      const videoSender = trackSenders.find((s) => s.track?.kind === 'video');
      const audioSender = trackSenders.find((s) => s.track?.kind === 'audio');
      if (videoSender) {
        videoSender.replaceTrack(this.#streams.provided.getVideoTracks()[0]);
      }
      if (audioSender) {
        audioSender.replaceTrack(this.#streams.provided.getAudioTracks()[0]);
      }
      if (!videoSender && !audioSender) {
        for (const track of this.#streams.provided?.getTracks() ?? []) {
          this.#peer.addTrack(track, this.#streams.provided);
        }
      }
    } else {
      for (const track of this.#streams.provided?.getTracks() ?? []) {
        this.#peer.addTrack(track, this.#streams.provided);
      }
    }
    // Этот аргумент передается только у провайдера
    if (!isTracksProvided) return;
    let shouldRemakeOffer = false;
    for (const type of ['audio', 'video'] as Array<keyof AudioVideoData>) {
      if (isTracksProvided[type] && !this.#isTracksProvided[type]) {
        this.#isTracksProvided[type] = true;
        shouldRemakeOffer = true;
      }
    }
    if (shouldRemakeOffer && this.#isInitialised) {
      // необходимо сформировать оффер заново, тк в стриме появился новый тип контента
      this.#reestablishConnection();
    }
  }

  public finishSession(): void {
    this.#wsClient.stop();
    this.#endSession();
  }

  #initSocket(): void {
    const socket = new WsRpcClient(defineServerUrl('WS'));
    socket.on(EServerEvents.INCOMING_REQUEST, (e) => this.#processRequest(e.data));
    socket.on(EServerEvents.OTHER_USER_LEAVE, (e) => {
      if (e.data[0] === this.#viewerId) {
        console.debug(`User [${this.#viewerId}] quit, closing connection`);
        this.#endSession();
      }
    });
    socket.on(EServerEvents.OTHER_USER_JOIN, (e) => this.#connectWithOtherUser(e.data[0]));
    socket.start();
    this.#wsClient = socket;
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  #processRequest(data: any[]): void {
    console.debug('Incoming request', data);
    if (!this.#viewerId) {
      [this.#viewerId] = data;
    }
    switch (data[1].reqType) {
      case EPartnerRequests.OFFER_PROVIDED:
        this.#provideAnswer(data[1].descObj, data[1].candidates, data[1].codec);
        break;
      case EPartnerRequests.ANSWER_PROVIDED:
        this.#handleAnswer(data[1].descObj, data[1].candidates);
        break;
      default:
        console.error(`Unsupported request type: [${data[1].reqType}]`);
        break;
    }
  }

  #provideIceCandidate(e: RTCPeerConnectionEventMap['icecandidate']): void {
    if (!e.candidate) return;
    this.#candidatesList.push(e.candidate);
  }

  #handleIncomingTracks(e: RTCPeerConnectionEventMap['track']): void {
    console.debug('Got stream and tracks');
    const receivedStream = new MediaStream();
    for (const track of e.streams[0]?.getTracks() ?? []) {
      receivedStream.addTrack(track);
    }
    this.#streams.received = receivedStream;
  }

  #handleConnectionStateChange(): void {
    const { iceConnectionState: state } = this.#peer;
    console.debug(`Peer status: [${state}]`);
    this.#currentConnectionState = state;
    if (this.#currentConnectionState !== state && ['failed', 'disconnect'].includes(state)) {
      Sentry.captureMessage(`Соединение с пользователем [${this.#viewerId}] изменилось с [${this.#currentConnectionState}] на [${state}]`);
    }
    if (state === 'disconnected' && this.#jccRole === 'provider') {
      console.debug('Connection lost, reconnecting');
      this.#reestablishConnection();
    }
  }

  #reestablishConnection(): void {
    const viewerId = this.#viewerId;
    this.#viewerId = '';
    this.#closeConnection();
    this.#connectWithOtherUser(viewerId);
  }

  #endSession(): void {
    this.#closeConnection();
    this.#isInitialised = false;
    this.#viewerId = '';
  }

  #closeConnection(): void {
    for (const event of this.#peerEventsList) {
      this.#peer.removeEventListener(event, this.#eventsHandlers.peer[event]);
    }
    this.#peer.close();
    this.#peer = null as unknown as RTCPeerConnection;
    if (this.#dataChannel) {
      // this.#dataChannel.removeEventListener('message', this.#eventsHandlers.dataChannel.message);
      this.#dataChannel.close();
      this.#dataChannel = null as unknown as RTCDataChannel;
    }
    this.#partnerAwaitStatus.isConnected = false;
    this.#streams.received = null;
    this.#currentConnectionState = '';
  }

  async #gatherAllCandidates(): Promise<void> {
    const gatherEndReason = await Promise.race([
      waitUntilBecomes(this.#peer, 'iceGatheringState', 'complete', 'gather complete'),
      delay(CONFIG.ICE_CANDIDATES_GATHER_TIME_LIMIT, { resolveWith: 'timeout' }),
    ]);
    console.debug(`ICE candidates gathering ended, reason: [${gatherEndReason}]`);
  }

  #createPeer(): void {
    this.#peer = new RTCPeerConnection({ iceServers: this.#iceServers });
    for (const event of this.#peerEventsList) {
      this.#peer.addEventListener(event, this.#eventsHandlers.peer[event]);
    }
    console.debug('Peer created');
  }

  async #provideOffer(): Promise<void> {
    if (this.#jccRole !== 'provider') return;
    try {
      const offer = await this.#peer.createOffer();
      const versionIOS = getVersionOfIOS();
      const codecType = (!versionIOS || versionIOS > 11) ? 'default' : 'alt';
      const codec = CONFIG.VIDEO_CODEC[codecType];
      offer.sdp = trySetVideoCodec(offer.sdp as string, codec);
      await this.#peer.setLocalDescription(offer);
      await this.#gatherAllCandidates();
      console.debug('Sending offer');
      this.#wsClient.send({
        type: EServerEvents.PARTNER_REQUEST,
        data: [this.#viewerId, {
          reqType: EPartnerRequests.PROVIDING_OFFER,
          descObj: this.#peer.localDescription,
          candidates: this.#candidatesList,
          codec,
        }],
      });
      this.#candidatesList = [];
      this.#isInitialised = true;
    } catch (e) {
      Sentry.captureMessage('Ошибка при предоставлении оффера');
      Sentry.captureException(e);
      console.error('Negotiation error');
      console.error(e);
    }
  }

  async #provideAnswer(
    offerObj: RTCSessionDescriptionInit,
    candidates: RTCIceCandidate[],
    codec: string
  ): Promise<void> {
    console.debug('Got offer, providing answer');
    if (this.#peer) {
      this.#closeConnection();
    }
    this.#createPeer();
    try {
      this.#partnerAwaitStatus.isConnected = true;
      await waitUntilBecomes(this.#streams, 'provided', true);
      /*
       * На вьювере стрим формируется только при присоединении провайдера,
       * и там сразу же вызывается метод reformProvidedStream данного класса,
       * который и добавляет треки к пиру.
       */
      const description = new RTCSessionDescription(offerObj);
      await this.#peer.setRemoteDescription(description);
      const answer = await this.#peer.createAnswer();
      answer.sdp = trySetVideoCodec(answer.sdp as string, codec);
      await this.#peer.setLocalDescription(answer);
      await this.#gatherAllCandidates();
      this.#wsClient.send({
        type: EServerEvents.PARTNER_REQUEST,
        data: [this.#viewerId, {
          reqType: EPartnerRequests.PROVIDING_ANSWER,
          descObj: this.#peer.localDescription,
          candidates: this.#candidatesList,
        }],
      });
      this.#candidatesList = [];
      this.#processCandidates(candidates);
    } catch (e) {
      Sentry.captureMessage('Ошибка при предоставлении ответа');
      Sentry.captureException(e);
      console.error('Answer providing error');
      console.error(e);
    }
  }

  async #handleAnswer(
    answerObj: RTCSessionDescriptionInit,
    candidates: RTCIceCandidate[],
  ): Promise<void> {
    console.debug('Got answer');
    try {
      await this.#peer.setRemoteDescription(new RTCSessionDescription(answerObj));
      this.#partnerAwaitStatus.isConnected = true;
      this.#processCandidates(candidates);
    } catch (e) {
      Sentry.captureMessage('Ошибка при обработке ответа');
      Sentry.captureException(e);
      console.error('Answer processing error');
      console.error(e);
    }
  }

  async #processCandidates(candidatesList: RTCIceCandidate[]): Promise<void> {
    console.debug('Processing ICE candidates');
    for (const candidate of candidatesList) {
      const candidateObj = new RTCIceCandidate(candidate);
      console.debug('Candidate', candidateObj);
      try {
        await this.#peer.addIceCandidate(new RTCIceCandidate(candidate));
        console.debug('Processing finished');
      } catch (e) {
        Sentry.captureMessage('Ошибка при обработке ICE кандидата');
        Sentry.captureException(e);
        console.error('ICE candidate processing error');
        console.error(e);
      }
    }
    if (candidatesList === this.#candidatesList) {
      this.#candidatesList = [];
    }
  }

  async #connectWithOtherUser(userId: string): Promise<void> {
    if (this.#jccRole !== 'provider' || this.#viewerId) return;
    console.debug(`Establishing connection with user [${userId}]`);
    this.#viewerId = userId;
    if (!this.#peer) {
      this.#createPeer();
      for (const track of this.#streams.provided?.getTracks() ?? []) {
        (this.#peer as RTCPeerConnection).addTrack(track, this.#streams.provided);
      }
    }
  }
}


export default Connector;
