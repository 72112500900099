// Find the line in sdpLines[startLine...endLine - 1] that starts with |prefix|
// and, if specified, contains |substr| (case-insensitive search).
const findLineInRange = (
  sdpLines: string[],
  startIndex: number,
  endIndex: number,
  prefix: string,
  substr: string,
  direction = 'asc'
): number | null => {
  if (direction === 'asc') {
    // Search beginning to end
    const realEndLine = endIndex !== -1 ? endIndex : sdpLines.length;
    for (let i = startIndex; i < realEndLine; ++i) {
      if (
        sdpLines[i].indexOf(prefix) === 0
        && (
          !substr || sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1
        )
      ) {
        return i;
      }
    }
  } else {
    // Search end to beginning
    const realStartLine = startIndex !== -1 ? startIndex : sdpLines.length - 1;
    for (let j = realStartLine; j >= 0; --j) {
      if (
        sdpLines[j].indexOf(prefix) === 0
        && (
          !substr || sdpLines[j].toLowerCase().indexOf(substr.toLowerCase()) !== -1
        )
      ) {
        return j;
      }
    }
  }
  return null;
};

// Returns a new m= line with the specified codec as the first one.
const setDefaultCodec = (mLine: string, payload: string): string => {
  const elements = mLine.split(' ');
  // Just copy the first three parameters; codec order starts on fourth.
  const newLine = elements.slice(0, 3);
  // Put target payload first and copy in the rest.
  newLine.push(payload);
  for (let i = 3; i < elements.length; i += 1) {
    if (elements[i] !== payload) {
      newLine.push(elements[i]);
    }
  }
  return newLine.join(' ');
};

const tryPreferCodec = (sdp: string, type: string, codec: string): string => {
  const sdpLines = sdp.split('\r\n');
  // Search for m line.
  const mLineIndex = findLineInRange(sdpLines, 0, -1, 'm=', type);
  if (mLineIndex === null) {
    return sdp;
  }
  // If the codec is available, set it as the default in m line.
  let payload = null;
  // Iterate through rtpmap enumerations to find all matching codec entries
  for (let i = sdpLines.length - 1; i >= 0; --i) {
    // Finds first match in rtpmap
    const index = findLineInRange(sdpLines, i, 0, 'a=rtpmap', codec, 'desc');
    if (index !== null) {
      // Skip all of the entries between i and index match
      i = index;
      // Get the codec payload type from an a=rtpmap:X line.
      const result = sdpLines[index].match(new RegExp('a=rtpmap:(\\d+) [a-zA-Z0-9-]+\\/\\d+'));
      payload = (result && result.length === 2) ? result[1] : null;
      if (payload) {
        // Move codec to top
        sdpLines[mLineIndex] = setDefaultCodec(sdpLines[mLineIndex], payload);
      }
    } else {
      // No match means we can break the loop
      break;
    }
  }
  return sdpLines.join('\r\n');
};

const trySetVideoCodec = (sdp: string, codec: string): string => tryPreferCodec(sdp, 'video', codec);

const getVersionOfIOS = (): number => {
  const agent = window.navigator.userAgent;
  const start = agent.indexOf('OS ');
  if ((agent.match(/iPhone|iPad/)) && (start > -1)) {
    return +(agent.substr(start + 3, 3).replace('_', '.'));
  }
  return 0;
};

export { trySetVideoCodec, getVersionOfIOS };
