/* eslint-disable @typescript-eslint/no-explicit-any */

import EventEmitter from 'eventemitter3';
import { delay, Deferred, blobToObj } from '@jcc/tools/utils';
import type { Deferred as Defer } from '@jcc/tools/utils';
import { EServerEvents, EPartnerRequests } from './constants';


type SocketState = 'CONNECTING' | 'OPEN' | 'CLOSING' | 'CLOSED';
type JccWsRpcError = { code: number, reason: string };
type JccClientMessage = { type: string, data?: any };
type JccWsRpcMessageData = { message: JccClientMessage, id: number, defer: Defer };
type JccServerMessage = {
  jsonrpc: string;
  id: number;
};
type JccServerResponseMessage = JccServerMessage & {
  result?: any;
  error?: {
    code: number;
    data: string;
    message: string;
  };
};
type JccServerRequestMessage = JccServerMessage & {
  method: string;
  params: any;
};
type ReqsTimeouts = Record<number | string, NodeJS.Timeout>;

class WsRpcClient {
  #ws = null as unknown as WebSocket;
  #lastReconnectTime = null as unknown as number;
  #reconnectTimeout = 5000;
  #nextReqId = 1;
  #toSend: JccWsRpcMessageData[] = [];
  #toReq: Record<keyof ReqsTimeouts, Defer> = {};
  #reqsTimeouts: ReqsTimeouts = {};
  #address!: string;
  #allowReconnect!: boolean;
  #emitter = new EventEmitter();
  #partnerResToReq: Record<string, string> = {
    [EPartnerRequests.PROVIDING_OFFER]: EPartnerRequests.OFFER_PROVIDED,
    [EPartnerRequests.PROVIDING_ANSWER]: EPartnerRequests.ANSWER_PROVIDED,
    [EPartnerRequests.PROVIDING_ICE_CANDIDATE]: EPartnerRequests.ICE_CANDIDATE_PROVIDED,
  };

  constructor(address: string) {
    this.#address = address;
    this.start();
  }

  get #state(): SocketState {
    return ([
      'CONNECTING',
      'OPEN',
      'CLOSING',
      'CLOSED',
    ] as SocketState[])[this.#ws?.readyState ?? 3];
  }

  get #isOpenOrConnecting(): boolean {
    return ['CONNECTING', 'OPEN'].includes(this.#state);
  }

  public on(eventName: string, handler: (...args: any[]) => void): void {
    this.#emitter.on(eventName, handler);
  }

  public start(): void {
    this.#allowReconnect = true;
    this.#connect();
  }

  public stop(): void {
    this.#allowReconnect = false;
    this.#forceCloseConnection();
  }

  public async send(message: JccClientMessage, timeout: null | number = null): Promise<any> {
    const id = this.#nextReqId;
    this.#nextReqId += 1;
    const d = new Deferred();
    if (timeout) {
      this.#reqsTimeouts[id] = setTimeout(() => {
        this.#onReqTimeout(id, message.type, d);
      }, timeout);
    }
    this.#putInQueue(message, id, d);
    return d.promise;
  }

  #forceCloseConnection(): void {
    console.debug('Force close connection.');
    if (this.#ws) {
      this.#ws.onopen = null;
      this.#ws.onclose = null;
      this.#ws.onmessage = null;
      this.#ws.close();
      this.#ws = null as unknown as WebSocket;
    }
    this.#onClose({
      reason: 'manually closed',
      code: 0,
    });
  }

  async #connect(): Promise<void> {
    if (this.#isOpenOrConnecting) {
      console.debug('Connection is working, skipping connect');
      return;
    }
    if (this.#lastReconnectTime && Date.now() - this.#lastReconnectTime < 0) {
      console.debug(`Delay before creating new connection: ${this.#reconnectTimeout}ms.`);
      await delay(this.#reconnectTimeout);
    }
    this.#lastReconnectTime = Date.now() + this.#reconnectTimeout;
    console.debug(`Connecting to [${this.#address}]`);
    this.#ws = new WebSocket(this.#address);
    this.#ws.onopen = this.#onOpen.bind(this);
    this.#ws.onclose = this.#onClose.bind(this);
    this.#ws.onmessage = this.#onMessage.bind(this);
  }

  #onOpen(): void {
    console.debug('WS connected');
    this.#invokeSend();
  }

  #onClose(error: JccWsRpcError): void {
    const errorMessage = `Connection closed: ${error.reason}[${error.code}]`;
    console.debug(errorMessage);
    this.#setReqError(errorMessage);
    if (this.#allowReconnect) {
      this.#connect();
    }
  }

  #setReqError(errorMessage: string): void {
    for (const id of Object.keys(this.#toReq)) {
      const defer = this.#toReq[id];
      if (defer && !defer.isDone) {
        defer.reject(new Error(errorMessage));
      }
      delete this.#toReq[id];
      this.#clearReqTimer(id);
    }
  }

  #clearReqTimer(id: keyof ReqsTimeouts): void {
    clearTimeout(this.#reqsTimeouts[id]);
    delete this.#reqsTimeouts[id];
  }

  #onReqTimeout(id: keyof ReqsTimeouts, name: string, defer: Defer): void {
    clearTimeout(this.#reqsTimeouts[id]);
    delete this.#reqsTimeouts[id];
    delete this.#toReq[id];
    if (!defer) {
      console.error('Invalid deferred object.');
      return;
    }
    if (defer.isDone) {
      return;
    }
    defer.reject(new Error(`${name} timeout occurs.`));
  }

  #formMessage(message: JccClientMessage, id: number, isResponse = false): string {
    const messageObj = {
      jsonrpc: '2.0',
      id,
    };
    if (isResponse) {
      (messageObj as Partial<JccServerResponseMessage>).result = 'ok';
    } else {
      (messageObj as Partial<JccServerRequestMessage>).method = message.type;
      if (message.data) {
        (messageObj as Partial<JccServerRequestMessage>).params = message.data;
      }
    }
    console.debug(`Sending ${isResponse ? 'response' : 'request'}`, messageObj);
    return JSON.stringify(messageObj);
  }

  #putInQueue(message: JccClientMessage, id: number, defer: Defer): void {
    this.#toSend.push({ message, id, defer });
    if (this.#state === 'OPEN') {
      this.#invokeSend();
    }
  }

  #invokeSend(): void {
    while (this.#toSend.length) {
      const { message, id, defer } = this.#toSend.shift() as JccWsRpcMessageData;
      this.#ws.send(this.#formMessage(message, id));
      this.#toReq[id] = defer;
    }
  }

  async #onMessage(ev: WebSocketEventMap['message']): Promise<void> {
    const message: JccServerMessage = await blobToObj(ev.data);
    if (!(message.id in this.#toReq)) {
      this.#onRequest(message as JccServerRequestMessage);
    } else {
      this.#onResponse(message as JccServerResponseMessage);
    }
  }

  #onRequest(message: JccServerRequestMessage): void {
    console.debug('Request', message);
    this.#ws.send(this.#formMessage({} as JccClientMessage, message.id, true));
    const isPartnerRequest = message.method === EServerEvents.PARTNER_REQUEST;
    const eventName = !isPartnerRequest
      ? message.method
      : EServerEvents.INCOMING_REQUEST;
    const eventData = { data: message.params };
    if (isPartnerRequest) {
      eventData.data[1].reqType = this.#partnerResToReq[message.params[1].reqType];
    }
    this.#emitter.emit(eventName, eventData);
  }

  #onResponse(message: JccServerResponseMessage): void {
    console.debug('Response', message);
    const { id, result, error } = message;
    const defer = this.#toReq[id];
    delete this.#toReq[id];
    this.#clearReqTimer(id);
    if (error) {
      defer.reject(new Error(`${error.message}: ${error.data}`));
    } else {
      defer.resolve(result);
    }
  }
}

export default WsRpcClient;
