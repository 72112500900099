/* eslint-disable @typescript-eslint/no-explicit-any */

import * as Sentry from '@sentry/vue';
import CONFIG from '@jcc/config';
import { getAudioContext } from '@jcc/tools/utils';
import { EMediaErrors, EMediaErrorsTypes } from './constants';


type AudioVideoData<A = any, V = A> = { audio: A, video: V };
type MediaError = {
  type: string;
  reason: string;
};
type StreamObtainRes = { stream: MediaStream, errors: MediaError[] };
type VideoResolution = 'SD' | 'FullHD';
type VideoSettings = Record<VideoResolution, MediaTrackConstraints>;
type DeviceData = Omit<MediaDeviceInfo, 'toJSON'>;


const deviceIdPattern1 = /[a-z][0-9]+/;
const deviceIdPattern2 = /[0-9][a-z]+/;
const labelSlicedPartPattern = /.+-\s/;

class MediaProvider {
  #prevSelectedDevices!: AudioVideoData<string>;
  #prevResolution = '' as VideoResolution;
  #isViewerStreamInitiallyRequested = false;

  constructor() {
    if (process.env.NODE_ENV === 'test') {
      (this as any).formStream = this.#formStream;
    }
  }

  toggleStreamTracks(stream: MediaStream, devicesStatuses?: AudioVideoData<boolean>): void {
    for (const track of stream.getTracks()) {
      track.enabled = devicesStatuses ? devicesStatuses[track.kind as keyof AudioVideoData] : true;
    }
  }

  stopStream(stream: MediaStream): void {
    this.#stopStreamTracks(stream.getTracks());
  }

  async getDevicesList(): Promise<AudioVideoData<DeviceData[]>> {
    const devicesData = await navigator.mediaDevices.enumerateDevices();
    const addedDevicesGroupIds = { audio: '', video: '' };
    return devicesData
      .filter(({ kind }) => kind.indexOf('input') !== -1)
      .reduce((acc, dd) => {
        const destination: keyof AudioVideoData = dd.kind.indexOf('audio') === -1 ? 'video' : 'audio';
        const target = acc[destination];
        if (dd.label) {
          const deviceData = {
            deviceId: dd.deviceId,
            kind: dd.kind,
            groupId: dd.groupId,
            label: dd.label.replace(labelSlicedPartPattern, ''),
          };
          if (!addedDevicesGroupIds[destination].match(dd.groupId)) {
            target.push(deviceData);
            addedDevicesGroupIds[destination] += `${dd.groupId} `;
          } else {
            const currentDeviceInstance = target.find(
              (tdd) => tdd.groupId === dd.groupId
            ) as MediaDeviceInfo;
            if (
              (dd.deviceId.match(deviceIdPattern1) || dd.deviceId.match(deviceIdPattern2))
              && !(
                currentDeviceInstance.deviceId.match(deviceIdPattern1)
                || currentDeviceInstance.deviceId.match(deviceIdPattern2)
              )
            ) {
              target[target.indexOf(currentDeviceInstance)] = deviceData;
            }
          }
        }
        return acc;
      }, { audio: [], video: [] } as AudioVideoData<DeviceData[]>);
  }

  async getViewerStream(devicesNames: AudioVideoData<string[], string>): Promise<MediaStream> {
    let stream: MediaStream;
    if (!this.#isViewerStreamInitiallyRequested) {
      try {
        await this.#getCameraStream({ audio: true, video: true });
      } catch (e) {
        Sentry.captureMessage('Ошибка при первоначальном получении стрима [viewer]');
        Sentry.captureException(e);
        console.error('Camera stream error');
        console.error(e);
      }
      this.#isViewerStreamInitiallyRequested = true;
    }
    const { audio, video } = await this.getDevicesList();
    const checkedDevicesNames = { ...devicesNames };
    if (
      audio.find((ad) => ad.label === 'Fake Default Audio Input')
      || video.find((vd) => vd.label === 'fake_device_0')
    ) {
      // для тестовых запусков
      checkedDevicesNames.audio = ['Fake Default Audio Input'];
      checkedDevicesNames.video = 'fake_device_0';
    }
    const audioDevicesIds = audio
      .filter((ad) => checkedDevicesNames.audio.some((name) => ad.label.indexOf(name) !== -1))
      .map((ad) => ad.deviceId);
    const videoDeviceId = video.find((vd) => vd.label === checkedDevicesNames.video)?.deviceId;
    const tracks: MediaStreamTrack[] = [];
    try {
      const audioContext = getAudioContext();
      const mergedAudio = audioContext.createMediaStreamDestination();
      for (const id of audioDevicesIds) {
        stream = await this.#getCameraStream(<MediaStreamConstraints>{
          audio: {
            deviceId: { exact: id },
            autoGainControl: false,
          },
        });
        audioContext.createMediaStreamSource(stream).connect(mergedAudio);
      }
      tracks.push(...mergedAudio.stream.getAudioTracks());
    } catch (e) {
      Sentry.captureMessage('Ошибка при получении аудио стрима [viewer]');
      Sentry.captureException(e);
      console.error('Audio error');
      console.error(e);
    }
    if (videoDeviceId) {
      try {
        stream = await this.#getCameraStream({
          video: {
            deviceId: { exact: videoDeviceId },
            ...CONFIG.PROVIDED_VIDEO_SETTINGS.HD,
          },
        });
        tracks.push(...stream.getVideoTracks());
      } catch (e) {
        try {
          stream = await this.#getCameraStream({
            video: {
              deviceId: { exact: videoDeviceId },
              ...CONFIG.PROVIDED_VIDEO_SETTINGS.SD,
            },
          });
          tracks.push(...stream.getVideoTracks());
        } catch (err) {
          Sentry.captureMessage('Ошибка при получении видео стрима [viewer]');
          Sentry.captureException(e);
          console.error('Video error');
          console.error(err);
        }
      }
    }
    return this.#formStream(tracks);
  }

  async getProviderStream(
    currentStream: MediaStream | null,
    streamSettings: MediaStreamConstraints,
    devicesStatuses: AudioVideoData<boolean>,
    videoSettings: VideoSettings,
    currentResolution: VideoResolution,
    selectedDevices: AudioVideoData<string>,
  ): Promise<StreamObtainRes> {
    let userMediaStream;
    const tracks: MediaStreamTrack[] = [];
    const settings = { ...streamSettings };
    const errors: MediaError[] = [];
    if (this.#prevSelectedDevices?.audio !== selectedDevices.audio) {
      this.#stopStreamTracks(currentStream?.getAudioTracks() ?? []);
      try {
        userMediaStream = await this.#getCameraStream({ audio: settings.audio });
        tracks.push(...userMediaStream.getAudioTracks());
      } catch (e: any) {
        Sentry.captureMessage('Ошибка при получении аудио стрима [provider]');
        Sentry.captureException(e);
        console.error('User audio error');
        console.error(e);
        errors.push({
          type: EMediaErrorsTypes.USER_AUDIO,
          reason: e.message,
        });
      }
    } else if (currentStream) {
      tracks.push(...currentStream.getAudioTracks());
    }
    const isDeviceChanged = selectedDevices.video !== this.#prevSelectedDevices?.video;
    let shouldReformVideo = isDeviceChanged;
    if (!isDeviceChanged && selectedDevices.video !== 'desktop') {
      shouldReformVideo = currentResolution !== this.#prevResolution;
    }
    if (shouldReformVideo) {
      this.#stopStreamTracks(currentStream?.getVideoTracks() ?? []);
      if (selectedDevices.video === 'desktop') {
        try {
          const displayMediaStream = await this.#getDesktopStream({ video: true });
          tracks.push(...displayMediaStream.getVideoTracks());
        } catch (e: any) {
          Sentry.captureMessage('Ошибка при получении видео стрима рабочего стола [provider]');
          Sentry.captureException(e);
          console.error('Desktop video error');
          console.error(e);
          errors.push({
            type: EMediaErrorsTypes.DESKTOP_VIDEO,
            reason: e.message,
          });
        }
      } else {
        this.#prevResolution = currentResolution;
        try {
          userMediaStream = await this.#getCameraStream({ video: settings.video });
          tracks.push(...userMediaStream.getVideoTracks());
        } catch (err) {
          if (currentResolution === 'FullHD') {
            settings.video = {
              ...settings.video as Record<string, unknown>,
              ...videoSettings.SD,
            };
            try {
              userMediaStream = await this.#getCameraStream({ video: settings.video });
              tracks.push(...userMediaStream.getVideoTracks());
              this.#prevResolution = 'SD';
            } catch (e: any) {
              Sentry.captureMessage('Ошибка при получении видео стрима [provider] в качестве [SD]');
              Sentry.captureException(e);
              console.error('User video low resolution error');
              console.error(e);
              errors.push({
                type: EMediaErrorsTypes.USER_VIDEO,
                reason: e.message,
              });
            }
          } else {
            Sentry.captureMessage('Ошибка при получении видео стрима [provider] в качестве [SD]');
            Sentry.captureException(err);
            console.error('User video low resolution error');
            console.error(err);
            // Эта ошибка пушится для того, чтобы отрубить возможность выбора разрешения
            errors.push(...[
              {
                type: EMediaErrorsTypes.USER_VIDEO_RESOLUTION,
                reason: EMediaErrors.LOWQ_VIDEO_UNAVAILABLE,
              },
              {
                type: EMediaErrorsTypes.USER_VIDEO,
                reason: EMediaErrors.SOURCE_UNAVAILABLE,
              },
            ]);
          }
        }
      }
    } else if (currentStream) {
      tracks.push(...currentStream.getVideoTracks());
    }
    this.#prevSelectedDevices = { ...selectedDevices };
    return {
      stream: this.#formStream(tracks, devicesStatuses),
      errors,
    };
  }

  #stopStreamTracks(tracks: MediaStreamTrack[]): void {
    for (const track of tracks) {
      track.stop();
    }
  }

  #formStream(tracks: MediaStreamTrack[], devicesStatuses?: AudioVideoData<boolean>): MediaStream {
    const stream = new MediaStream();
    for (const track of tracks) {
      stream.addTrack(track);
    }
    this.toggleStreamTracks(stream, devicesStatuses);
    return stream;
  }

  async #getDesktopStream(settings: MediaStreamConstraints): Promise<MediaStream> {
    return navigator.mediaDevices.getDisplayMedia(settings);
  }

  async #getCameraStream(settings: MediaStreamConstraints): Promise<MediaStream> {
    return navigator.mediaDevices.getUserMedia(settings);
  }
}


export default MediaProvider;
export {
  deviceIdPattern1,
  deviceIdPattern2,
  labelSlicedPartPattern
};
export type {
  AudioVideoData,
  MediaError,
  VideoResolution,
  VideoSettings,
  DeviceData
};
